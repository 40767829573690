<script>
  import { router } from 'tinro'
  import { createEventDispatcher, onMount } from 'svelte'
  import BrowserCheck from './login/BrowserCheck.svelte'
  import { getCookie, generateUUID, setCookie } from './Utils.svelte'
  import {
    compatibility,
    termsAndConditionOpen,
    privacyPolicyOpen,
    BASE_URL,
    lessonPos,
    userDetail,
  } from './DataStore'

  import TermsAndConditions from './login/TermsAndConditions.svelte'
  import PrivacyPolicy from './login/PrivacyPolicy.svelte'

  export let pinFromUrl = false
  export let pin = undefined
  let userName = ''
  let loginToken = undefined
  let errorMsg = undefined

  let isOpen = false

  const dispatch = createEventDispatcher()

  let compatibilityValue

  const unsubscribe = compatibility.subscribe((x) => {
    compatibilityValue = x
  })

  onMount(async () => {
    initUserName()
    mixpanel.track_links(
      '#sensay_home',
      'Student Practice Sensay Banner Clicked'
    )
  })

  async function login() {
    userName = userName?.trim()
    pin = pin?.trim()

    console.log('click login', pin, userName)
    mixpanel.track('Student Practice Log In Button Clicked', {
      'Student Name': userName,
      PIN: pin,
    })
    if (pin && userName && pin === userName) {
      errorMsg = 'PIN match Name'
      return
    }

    if (pin && pin != '' && userName && userName != '') {
      console.log('dispatch')

      if (pin.length === 5) {
        fetch(BASE_URL + `public/api/lessons?key=${pin}`)
          .then((response) => {
            if (!response.ok) {
              console.error('response error')
              throw new Error('Network response was not ok', {
                cause: { url: response?.url, status: response?.status },
              })
            }
            console.log('response ok')
            return response.json()
          })
          .then((data) => {
            setCookie('shuoshuoUserName', userName, 30)
            const msg = {
              text: 'PIN OK',
              pin: data.key,
              userName: userName,
              loginToken: loginToken,
              lessonId: data.id,
            }
            $userDetail = { userName: userName, loginToken: loginToken }
            dispatch('login', msg)
          })
          .catch((error) => {
            console.error('catch error', error)
            errorMsg = 'Missing PIN'
            try {
              mixpanel.track('MissingPIN', {
                pin: pin,
                errorMsg: error.toString(),
                cause: JSON.stringify(error.cause),
              })
              Bugsnag.notify(new Error(error.toString()))
            } catch (error) {
              console.log('error mixpanel')
            }
          })
        return
      }

      if (Number(pin)) {
        const msg = {
          text: 'PIN OK',
          pin: pin,
          userName: userName,
          loginToken: loginToken,
          lessonId: pin,
        }
        $userDetail = { userName: userName, loginToken: loginToken }
        setCookie('shuoshuoUserName', userName, 30)
        dispatch('login', msg)
        return
      }

      fetch(
        BASE_URL +
          `public/api/exercises?key=` +
          pin +
          '&studentLogin=' +
          userName
      )
        .then((response) => {
          if (!response.ok) {
            console.error('response error')
            throw new Error('Network response was not ok', {
              cause: { url: response?.url, status: response?.status },
            })
          }
          console.log('response ok')
          return response.json()
        })
        .then((data) => {
          setCookie('shuoshuoUserName', userName, 30)
          const msg = {
            text: 'PIN OK',
            pin: pin,
            userName: userName,
            loginToken: loginToken,
          }
          $userDetail = { userName: userName, loginToken: loginToken }
          dispatch('login', msg)
        })
        .catch((error) => {
          console.error('catch error', error)
          errorMsg = 'Missing PIN'
          try {
            mixpanel.track('MissingPIN', {
              pin: pin,
              errorMsg: error.toString(),
              cause: JSON.stringify(error.cause),
            })
            Bugsnag.notify(new Error(error.toString()))
          } catch (error) {
            console.log('error mixpanel')
          }
        })
    } else {
      errorMsg = 'No PIN or Name'
    }
  }

  function initUserName() {
    loginToken = getCookie('shuoshuoToken')
    if (loginToken != null && loginToken != '') {
      userName = getCookie('shuoshuoUserName')
    } else {
      loginToken = generateUUID()
      setCookie('shuoshuoToken', loginToken, 30)
    }
  }

  function openTC() {}

  function resetPin() {
    console.log('resetPin')
    if (pin) {
      const url_string = window.location.href
      const url = new URL(url_string)
      window.history.pushState({}, url.origin, url.pathname)
      pin = undefined
      pinFromUrl = false
      dispatch('resetPin', {})
    }
  }
</script>

<TermsAndConditions />
<PrivacyPolicy />

<div class="login-clean">
  <form>
    <h2 class="sr-only">Login Form</h2>
    <div class="illustration-large" on:click={resetPin}>
      {#if !pinFromUrl}
        <a
          id="sensay_home"
          href="https://www.oksensay.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src="assets/img/logo_2.png" alt="logo" /></a
        >
      {:else}
        <img src="assets/img/logo_2.png" alt="logo" />
      {/if}
    </div>
    <div class="illustration-small" on:click={resetPin}>
      {#if !pinFromUrl}
        <a
          id="sensay_home"
          href="https://www.oksensay.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src="assets/img/logo_3.png" alt="logo" /></a
        >
      {:else}
        <img src="assets/img/logo_3.png" alt="logo" />
      {/if}
    </div>
    {#if pinFromUrl}
      <div style="display:flex; justify-content:space-between">
        <div><p>PIN already provided ({pin})</p></div>
        <div on:click={resetPin}><i class="fas fa-times mr-2" /></div>
      </div>
    {:else}
      <div class="form-group">
        <input
          class="form-control"
          type="text"
          placeholder="PIN"
          bind:value={pin}
        />
      </div>
    {/if}
    <div class="form-group">
      <input
        class="form-control"
        type="text"
        placeholder="Enter your first and last name"
        bind:value={userName}
      />
    </div>
    {#if $compatibility}
      <div class="form-group">
        <button
          class="btn btn-primary btn-block"
          type="submit"
          on:click|preventDefault={login}>Log In</button
        >
      </div>
    {:else}
      <div class="form-group">
        <button class="btn btn-danger btn-block" type="button" disabled
          >Error</button
        >
        <div class="alert alert-danger" role="alert">
          You are seeing this error message because you opened the link from
          LINE or FB messenger. Sensay only works when opened in compatible
          browser. Please open directly in Safari or another browser to access
          Sensay. <a href="https://www.oksensay.com/337-2/">Need help?</a>
        </div>
      </div>
    {/if}
    {#if errorMsg === 'Missing PIN'}
      <div class="alert alert-danger" role="alert">
        This exercise is not available.
      </div>
    {:else if errorMsg === 'No PIN or Name'}
      <div class="alert alert-danger" role="alert">
        Please fill token and name.
      </div>
    {:else if errorMsg === 'PIN match Name'}
      <div class="alert alert-danger" role="alert">
        Please don't use PIN as name.
      </div>
    {/if}

    <p>
      <i class="fas fa-info-circle mr-1" />Teacher should provide you with the
      PIN
    </p>

    By continuing, I agree to Sensay’s<button
      class="btn btn-link p-0"
      on:click|preventDefault={() => termsAndConditionOpen.update((x) => true)}
      >Terms of Use</button
    >
    and
    <button
      class="btn btn-link p-0"
      on:click|preventDefault={() => privacyPolicyOpen.update((x) => true)}
      >Privacy Policy</button
    >.
  </form>
</div>
<BrowserCheck />

<style>
  form {
    max-width: 450px;
  }
  img {
    width: 100%;
  }

  @media (max-width: 768px) {
    .illustration-large {
      display: none;
    }
    .illustration-small {
      padding: 0 0 20px;
    }

    .login-clean {
      padding: 20px 0 20px 0;
    }
  }
  @media (min-width: 768px) {
    .illustration-small {
      display: none;
    }
    .illustration-large {
      padding: 0 0 20px;
    }
  }
</style>
