<script>
  import { Route, router, meta } from 'tinro'
  import { onMount } from 'svelte'
  import ExerciseDetail from './ExerciseDetail.svelte'
  import LessonSelection from './LessonSelection.svelte'
  import LoginComponent from './LoginComponent.svelte'
  import { generateUUID } from './Utils.svelte'

  router.mode.hash()

  let status = 'LOGIN'
  let loginDetail = undefined
  let lessonStatus = false
  let lessonId = undefined

  let pin = undefined
  let pinFromUrl = false

  let iframe = undefined

  function handleMessage(event) {
    console.log(event)

    loginDetail = event.detail

    if (loginDetail?.lessonPin && loginDetail?.pin) {
      status = 'EXERCISE'
      lessonId = loginDetail?.lessonPin
      lessonId = loginDetail?.lessonPin
      router.goto(
        `/lesson/${loginDetail?.lessonPin}/exercise/${loginDetail?.pin}`
      )
    } else if (loginDetail?.lessonKey) {
      status = 'LESSON'
      lessonStatus = true
      router.goto('/lesson/' + loginDetail?.lessonKey)
    } else if (loginDetail?.lessonPin) {
      status = 'LESSON'
      lessonStatus = true
      lessonId = loginDetail?.lessonPin
      router.goto('/lesson/' + lessonId)
    } else if (Number(loginDetail?.pin) || loginDetail?.pin?.length === 5) {
      status = 'LESSON'
      lessonStatus = true
      lessonId = loginDetail?.pin
      router.goto('/lesson/' + lessonId)
    } else {
      status = 'EXERCISE'
      router.goto('/exercise/' + loginDetail?.pin)
    }

    console.log(loginDetail)
  }

  function resetPin(event) {
    console.log('resetPin')
    loginDetail = undefined
    lessonId = undefined
  }

  onMount(async () => {
    //status = true;
    //loginDetail = {text: 'PIN OK', pin: 'cyWnql', userName: 'abc', loginToken: 'xxx'};
    initKeyFromUrl()
  })

  function initKeyFromUrl() {
    const url_string = window.location.href
    const url = new URL(url_string)
    pin = url.searchParams.get('key')
    if (!!pin) {
      pinFromUrl = true
    }
    console.log('pin', pin)
    iframe = url.searchParams.get('iframe')
    let firstName = url.searchParams.get('firstName')
    if (!!iframe) {
      firstName = firstName ? firstName : ''
      loginDetail = {
        pin: pin,
        userName: firstName,
        loginToken: generateUUID(),
      }
      router.goto('/iframe/exercise/' + pin)
    }
  }

  // function goHome() {
  //   mixpanel.track('Student Practice Home Button Clicked')
  //   lessonStatus = false
  //   status = 'LOGIN'
  //   router.goto('/')
  // }

  function goLesson() {
    //lessonStatus = false;
    status = 'LESSON'
    loginDetail.pin = lessonId
    router.goto('/lesson/' + lessonId)
  }

  function prevExercise(e) {
    console.log('onPrevExercise', e)
    router.goto('/exercise/' + e?.detail?.exerciseKey)
  }

  function nextExercise(e) {
    console.log('onNextExercise', e)
    router.goto('/exercise/' + e?.detail?.exerciseKey)
  }
</script>

<div class="mainApp">
  <Route>
    <Route path="/" fallback>
      <LoginComponent
        on:login={handleMessage}
        on:resetPin={resetPin}
        {pin}
        {pinFromUrl}
      />
    </Route>
    {#if iframe}
      <Route path="/iframe/exercise/:exercisePin" let:meta>
        <ExerciseDetail
          on:message={handleMessage}
          on:openLesson={handleMessage}
          exercisePin={meta.params.exercisePin}
          {loginDetail}
          iframe="true"
        />
      </Route>
    {/if}
    {#if loginDetail}
      {#if lessonStatus}
        <Route path="/lesson/:lessonPin" let:meta>
          <LessonSelection
            on:openExercise={handleMessage}
            {loginDetail}
            lessonPin={meta.params.lessonPin}
          />
        </Route>
      {/if}

      <Route path="/exercise/:exercisePin" let:meta>
        <div>
          {#if lessonStatus}
            <button class="btn btn-link" on:click|preventDefault={goLesson}
              ><i class="fas fa-long-arrow-left mr-2" />Lesson</button
            >
          {/if}
        </div>
        {#key meta.params.exercisePin}
          <ExerciseDetail
            on:message={handleMessage}
            on:openLesson={handleMessage}
            on:prevExercise={prevExercise}
            on:nextExercise={nextExercise}
            exercisePin={meta.params.exercisePin}
            {loginDetail}
            {lessonStatus}
          />
        {/key}
      </Route>
      <Route path="/lesson/:lessonPin/exercise/:exercisePin" let:meta>
        <div>
          {#if lessonStatus}
            <button class="btn btn-link" on:click|preventDefault={goLesson}
              ><i class="fas fa-long-arrow-left mr-2" />Lesson</button
            >
          {/if}
        </div>
        {#key meta.params.exercisePin}
          <ExerciseDetail
            on:message={handleMessage}
            on:openLesson={handleMessage}
            on:prevExercise={prevExercise}
            on:nextExercise={nextExercise}
            exercisePin={meta.params.exercisePin}
            lessonPin={meta.params.lessonPin}
            {loginDetail}
            {lessonStatus}
          />
        {/key}
      </Route>
      <Route path="/">
        <LoginComponent
          on:login={handleMessage}
          on:resetPin={resetPin}
          {pin}
          {pinFromUrl}
        />
      </Route>
    {:else}
      <Route path="/">
        <LoginComponent
          on:login={handleMessage}
          on:resetPin={resetPin}
          {pin}
          {pinFromUrl}
        />
      </Route>
      <Route path="/lesson/:lessonPin" let:meta>
        <LoginComponent
          on:login={handleMessage}
          on:resetPin={resetPin}
          pin={pinFromUrl ? pin : meta.params.lessonPin}
          {pinFromUrl}
        />
      </Route>
      <Route path="/exercise/:exercisePin" let:meta>
        <LoginComponent
          on:login={handleMessage}
          on:resetPin={resetPin}
          pin={pinFromUrl ? pin : meta.params.exercisePin}
          {pinFromUrl}
        />
      </Route>
      <Route path="/lesson/:lessonPin/exercise/:exercisePin" let:meta>
        <LoginComponent
          on:login={handleMessage}
          on:resetPin={resetPin}
          pin={pinFromUrl ? pin : meta.params.lessonPin}
          {pinFromUrl}
        />
      </Route>
    {/if}
  </Route>
</div>

<style>
  @media (min-width: 768px) {
    .mainApp {
      margin-top: 1em;
      margin-left: 1em;
      margin-right: 1em;

      overflow-y: hidden;
    }
  }

  @media (max-width: 768px) {
    .mainApp {
      margin-top: 2px;
      margin-left: 2px;
      margin-right: 2px;

      overflow-y: hidden;
    }
  }
</style>
